import { validationMixin } from "vuelidate"
import { required } from "vuelidate/lib/validators"
import { cloneDeep } from "lodash-es"

const validation = {
  $each: {
    _addedAt: { required: false },
    _updated: { required: false },
    id: { required: false },
    name: { required },
    periods: {
      $each: {
        _addedAt: { required: false },
        id: { required: false },
        start: { required },
        end: { required }
      }
    }
  }
}

export default {
  mixins: [validationMixin],

  validations() {
    return {
      items: cloneDeep(validation),
      itemsAdded: cloneDeep(validation),
      itemsDeleted: cloneDeep(validation)
    }
  }
}
