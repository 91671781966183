<template lang="pug">
  .base-rate-period-settings
    AppOverlayLoader(:state="periodsLoading")
    TopBar(
      :filters="filters"
      :is-save-disabled="!isUnsavedItems"
      @change-filters="changeFilters"
      @add-new="handleAddNew"
      @save-changes="saveChanges"
    )
    PeriodSettingsTable(
      @sorting="changeSorting"
      :sorting-data="sorting"
      :items="$v.items"
      :items-added="$v.itemsAdded"
      @update-item="updateItem"
      @remove-period="handleRemovePeriod"
    )
    AppPagination(
      :current-page="pagination.current_page"
      :total="pagination.total_count"
      :per-page="pagination.per_page"
      @change-pagination-data="changePagination"
    )
</template>

<script>
  // misc
  import { isEmpty } from "lodash-es"
  import { appDebounce } from "@/helpers/common"

  // store modules
  import baseRatePeriodSettingsModule from "@/config/store/plan_management/base_rate_period_settings"

  // mixins
  import withStoreModule from "@/mixins/withStoreModule"
  import withConfirmation from "@/mixins/withConfirmation"
  import withValidations from "./validations"
  import withScrollTop from "@/mixins/withScrollTop"

  const baseRatePeriodSettingsMixin = withStoreModule(baseRatePeriodSettingsModule, {
    resetState: true,
    name: "baseRatePeriodSettings",
    readers: {
      sorting: "sorting",
      filters: "filters",
      pagination: "pagination",
      periodsLoading: "loading",
      items: "items",
      itemsAdded: "itemsAdded",
      itemsUpdated: "itemsUpdated",
      itemsDeleted: "itemsDeleted"
    },
    actions: {
      fetchPricePeriods: "FETCH_ITEMS",
      updatePricePeriods: "UPDATE_ITEMS"
    },
    mutations: {
      addItem: "ADD_ITEM",
      setItemByIndex: "SET_ITEM_BY_INDEX",
      setAddedItemByIndex: "SET_ADDED_ITEM_BY_INDEX",
      resetItems: "RESET_ITEMS",
      resetItemsAdded: "RESET_ITEMS_ADDED",
      setFilters: "SET_FILTERS",
      setSorting: "SET_SORTING",
      setPagination: "SET_PAGINATION_DATA"
    },
    getters: ["itemsUpdated"]
  })

  export default {
    components: {
      TopBar: () => import("./TopBar"),
      PeriodSettingsTable: () => import("./PeriodSettingsTable"),
      AppPagination: () => import("@/components/elements/AppPagination"),
      AppOverlayLoader: () => import("@/components/elements/AppOverlayLoader")
    },

    mixins: [withScrollTop, withValidations, withConfirmation, baseRatePeriodSettingsMixin],

    created() {
      this.debouncedFetchPricePeriods = appDebounce(() => this.fetchPricePeriods())
    },

    mounted() {
      this.fetchPricePeriods()
    },

    beforeRouteLeave(to, _from, next) {
      this.beforeRouteLeaveHandler({ to, next, isChanges: this.isUnsavedItems })
    },

    computed: {
      isUnsavedItems() {
        return !isEmpty(this.itemsAdded) || !isEmpty(this.itemsUpdated)
      }
    },

    methods: {
      saveChanges() {
        this.$v.$touch()

        if (this.$v.$invalid) {
          this.$nextTick(() => {
            this.scrollTo({ target: ".invalid", inline: "center" })
          })
          return
        }

        this.updatePricePeriods()
      },

      handleAddNew() {
        this.addItem({ item: { name: "", periods: [] } })
      },

      updateItem(item) {
        item.item._addedAt ? this.setAddedItemByIndex(item) : this.setItemByIndex(item)
      },

      handleRemovePeriod(baseRatePeriodSettingItem, index) {
        baseRatePeriodSettingItem.periods.splice(index, 1)
      },

      resetData() {
        this.resetItems()
        this.resetItemsAdded()
        this.debouncedFetchPricePeriods()
      },

      changeFilters(filters) {
        this.$conditionalConfirm({
          useConfirm: this.isUnsavedItems,
          handler: () => {
            this.setFilters(filters)
            this.setPagination({ ...this.pagination, current_page: 1 })
            this.resetData()
          }
        })
      },

      changePagination(paginationData) {
        this.$conditionalConfirm({
          useConfirm: this.isUnsavedItems,
          handler: () => {
            this.setPagination({ ...this.pagination, ...paginationData })
            this.resetData()
          }
        })
      },

      changeSorting(sorting) {
        this.$conditionalConfirm({
          useConfirm: this.isUnsavedItems,
          handler: () => {
            this.setSorting(sorting)
            this.resetData()
          }
        })
      }
    }
  }
</script>

<style lang="sass" scoped></style>
